import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PdpHeroBanner from '@cof/graffiti-alley-spray-cans/organisms/PdpHeroBanner';
import VerticalContentPDP from '@cof/graffiti-alley-spray-cans/atoms/VerticalContentPDP/VerticalContentPDP';
import IconSetPDP from '@cof/graffiti-alley-spray-cans/molecules/IconSetPDP/IconSetPDP';
import IconPDP from '@cof/graffiti-alley-spray-cans/atoms/IconPDP/IconPDP';
import { useCardDetails } from '../../hooks/useCardDetails';
import PdpAccordion from '@cof/graffiti-alley-spray-cans/molecules/PdpAccordion';
import PdpAccordionSection from '@cof/graffiti-alley-spray-cans/molecules/PdpAccordion/PdpAccordionSection';
import StickyBar from '@cof/graffiti-alley-spray-cans/molecules/StickyBar';
import CardArt from '@cof/graffiti-alley-spray-cans/molecules/CardArt';
import Loading from '@cof/graffiti-alley-spray-cans/atoms/Loading';

import PdpFees from '../../components/Pdp/PdpFees';
import PdpReview from '../../components/Pdp/PdpReview';
import CardTiles from '../../components/CardTiles';
import CardTileLearnMore from '../../components/CardTileLearnMore';
import CardTileSecure from '../../components/CardTileSecure';
import { LayoutPDP, LayoutPDPSection } from '../../components/LayoutPDP';
import FlipCardSet from '../../components/FlipCardSet/FlipCardSet';
import PdpLegalDisclaimer from '../../components/Pdp/PdpLegalDisclaimer';
import { productApiErrorHandler } from '../../lib/api';
import SingleCtaButton from '../../components/SingleCtaButton';
import { useWindowWidthSize } from '../../hooks/useWindowWidthSize';
import SEO from '../../components/SEO/SEO';

import heroBannerImage from '../../assets/images/nfsrm-pdp-hero-desktop.png';
import verticalContentCelebrateImage from '../../assets/images/pdp-vertical-content-celebrate-desktop.png';
import verticalContentCelebrateImageTablet from '../../assets/images/pdp-vertical-content-celebrate-tab.png';
import verticalContentCelebrateImageMobile from '../../assets/images/pdp-vertical-content-celebrate-mob.png';
import verticalContentPhoneImage from '../../assets/images/pdp-vertical-content-phone-desktop-transformed.png';
import verticalContentPhoneImageTablet from '../../assets/images/pdp-vertical-content-phone-tab-transformed.png';
import verticalContentPhoneImageMobile from '../../assets/images/pdp-vertical-content-phone-mobile-transformed.png';
import verticalContentPhoneImageFr from '../../assets/images/pdp-vertical-content-phone-desktop-fr-transformed.png';
import verticalContentPhoneImageTabletFr from '../../assets/images/pdp-vertical-content-phone-tab-fr-transformed.png';
import verticalContentPhoneImageMobileFr from '../../assets/images/pdp-vertical-content-phone-mobile-fr-transformed.png';
import pdpIconStepOne from '../../assets/images/pdp-icon-buy.svg';
import pdpIconStepTwo from '../../assets/images/pdp-icon-payments.svg';
import pdpIconStepThree from '../../assets/images/pdp-icon-rewarded.svg';
import pdpIconSupport from '../../assets/images/pdp-icon-support.svg';
import pdpIconChat from '../../assets/images/pdp-icon-chat.svg';
import pdpIconAgent from '../../assets/images/pdp-icon-agent.svg';
import './smart-rewards-mastercard.scss';

function getContentKey(intl, subPath) {
  return intl.formatMessage({ id: `pages.pdp.${subPath}` });
}

function getImagePerLanguage(intl, enImage, frImage) {
  return intl.locale === 'fr' ? frImage : enImage;
}

const brand = 'nfsrm';

const heroContents = (
  <>
    <FormattedMessage id="pages.pdp.hero.description" values={{ sup1: <sup>1</sup> }} />
    <FormattedMessage id={`pages.pdp.hero.sub-header0`} values={{ sup2: <sup>2</sup> }} />{' '}
    <FormattedMessage
      id={`pages.pdp.hero.sub-header1`}
      values={{
        noRiskCredit: (
          <strong>
            <FormattedMessage id="pages.pdp.hero.no-risk-credit" />
          </strong>
        ),
      }}
    />
  </>
);

const SmartRewardsPage = injectIntl(({ intl }) => {
  const width = useWindowWidthSize(1440);
  const { isLoading, isError, snippetDetails } = useCardDetails({
    intl,
    brand,
    snippetNames: ['annual-fee', 'purchase', 'cash-advance', 'balance-transfer'],
    options: { type: 'detail' },
    errorHandler: productApiErrorHandler,
    isErrorBoundaried: false,
  });
  const singleCtaLink = '/quickcheck/';

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LayoutPDP
      containerClass="smart-rewards-page-container"
      title={intl.formatMessage({ id: `pages.pdp.seo.page-title` })}
      description={intl.formatMessage({ id: `pages.pdp.seo.page-desc` })}
    >
      <LayoutPDPSection className="hero-banner-layout-wrapper" role="none">
        <PdpHeroBanner
          titleText={getContentKey(intl, 'hero.title')}
          header={<FormattedMessage id="pages.pdp.hero.h1" values={{ MD: <sup>MD</sup> }} />}
          content={width > 640 ? heroContents : ''}
          heroImgAlt={intl.formatMessage({ id: `cards.details.${brand}.image-alt` }) || ''}
          brand={brand}
          heroImg={heroBannerImage}
          subHeader={width > 640 ? undefined : (props) => <p {...props}>{heroContents}</p>}
          renderButton={(ariaDescribedBy) => (
            <SingleCtaButton
              ariaDescribedBy={ariaDescribedBy}
              brand={brand}
              url={singleCtaLink}
              ctaClassName={intl.locale === 'fr' ? 'nfsrm-cta nfsrm-cta-fr' : 'nfsrm-cta'}
              useRedesignLink
            />
          )}
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="icon-set-layout-wrapper">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'content.how-it-works.title')}</h2>
          <IconSetPDP>
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-1')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-1')}
              icon={pdpIconStepOne}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-2')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-2')}
              subCaptionSuper="3"
              icon={pdpIconStepTwo}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.how-it-works.step-3')}
              subCaption={getContentKey(intl, 'content.how-it-works.sub-caption-3')}
              icon={pdpIconStepThree}
              iconAlt=""
              bolded="bottom"
            />
          </IconSetPDP>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tiles-layout-wrapper">
        <CardTiles intl={intl} containerClass="pdp-card-tiles-container" />
      </LayoutPDPSection>

      {isError === false && (
        <LayoutPDPSection className="fees-layout-wrapper pdp-gray-background">
          <PdpFees intl={intl} feeSnippets={snippetDetails} />
        </LayoutPDPSection>
      )}

      <LayoutPDPSection className="vertical-contents-card-layout-wrapper pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'content.youve-got-this.title')}
          content={getContentKey(intl, 'content.youve-got-this.description')}
          image={
            window.innerWidth >= 1024
              ? verticalContentCelebrateImage
              : window.innerWidth >= 640
              ? verticalContentCelebrateImageTablet
              : verticalContentCelebrateImageMobile
          }
          imageAlt=""
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="flip-card-set-layout-wrapper pdp-gray-background">
        <FlipCardSet />
      </LayoutPDPSection>

      <LayoutPDPSection className="pdp-reviews-layout-wrapper pdp-gray-background">
        <PdpReview className="pdp-review-list-container" />
      </LayoutPDPSection>

      <LayoutPDPSection className="credit-vertical-contents-card-layout-wrapper pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'content.more-than-just.title')}
          content={getContentKey(intl, 'content.more-than-just.description')}
          image={
            window.innerWidth >= 1024
              ? getImagePerLanguage(intl, verticalContentPhoneImage, verticalContentPhoneImageFr)
              : window.innerWidth >= 640
              ? getImagePerLanguage(intl, verticalContentPhoneImageTablet, verticalContentPhoneImageTabletFr)
              : getImagePerLanguage(intl, verticalContentPhoneImageMobile, verticalContentPhoneImageMobileFr)
          }
          imageAlt=""
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tile-secure-layout-wrapper pdp-gray-background">
        <CardTileSecure
          titleLeft={intl.formatMessage({ id: 'pages.pdp.content.more-than-just.card-1.title' })}
          descriptionLeft={intl.formatMessage({ id: 'pages.pdp.content.more-than-just.card-1.description' })}
          titleRight={intl.formatMessage({ id: 'pages.pdp.content.more-than-just.card-2.title' })}
          descriptionRight={intl.formatMessage({ id: 'pages.pdp.content.more-than-just.card-2.description' })}
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tile-learn-more-layout-wrapper pdp-gray-background">
        <CardTileLearnMore brand={brand} />
      </LayoutPDPSection>

      <LayoutPDPSection className="icon-set-layout-wrapper pdp-gray-background">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'content.get-personalized.h2')}</h2>
          <IconSetPDP dividers={false}>
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-1')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-1')}
              icon={pdpIconSupport}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-2')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-2')}
              icon={pdpIconChat}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'content.get-personalized.caption-3')}
              subCaption={getContentKey(intl, 'content.get-personalized.sub-caption-3')}
              icon={pdpIconAgent}
              iconAlt=""
              bolded="top"
            />
          </IconSetPDP>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection className="faq">
        <h2 className="faq-title">{getContentKey(intl, 'content.faq.title')}</h2>
        <PdpAccordion className="pdp-accordion">
          {Array(5)
            .fill()
            .map((_, i) => {
              const contentIndex = i + 1;
              const faq = getContentKey(intl, `content.faq.${'q' + contentIndex}`);
              let faqContent;
              if (contentIndex <= 2) {
                faqContent = (
                  <>
                    <p>
                      <FormattedMessage
                        id={`pages.pdp.content.faq.p${i + 1}-1`}
                        values={{ sup2: <sup>2</sup>, MC: intl.locale === 'fr' ? <sup>MC</sup> : undefined }}
                      />
                    </p>
                    <p>
                      <FormattedMessage id={`pages.pdp.content.faq.p${i + 1}-2`} values={{ sup2: <sup>2</sup> }} />
                    </p>
                  </>
                );
              } else {
                faqContent = getContentKey(intl, `content.faq.${'p' + contentIndex}`);
              }
              return (
                <PdpAccordionSection key={i} title={faq}>
                  {faqContent}
                </PdpAccordionSection>
              );
            })}
        </PdpAccordion>
      </LayoutPDPSection>

      <LayoutPDPSection
        className={`legal-disclaimer-layout-wrapper ${intl.locale === 'en' ? 'extra-space-bottom' : ''}`}
      >
        <PdpLegalDisclaimer snippetDetails={snippetDetails} />
      </LayoutPDPSection>

      <StickyBar contentPosition="center" isVerticalDisplayinSmallScreen={false} stickybarPosition="bottom">
        <CardArt brand={brand} classImageStyle="stickybar-img" />
        <div className="stickybar-button single-cta">
          <SingleCtaButton brand={brand} url={singleCtaLink} ctaClassName="stickybar" useRedesignLink />
        </div>
      </StickyBar>
    </LayoutPDP>
  );
});

export default SmartRewardsPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.pdp.seo.page-title" descriptionKey="pages.pdp.seo.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
