import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import Table from '@cof/graffiti-alley-spray-cans/atoms/Table';
import './SRLegalModalContent.scss';

const SRLegalModalContent = ({ annualFee }) => {
  const row1 = (
    <tr key={0}>
      <th>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.annual-interest-rate1" />
      </th>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row1-1" />
      </td>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row1-2" />
      </td>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row1-3" />
      </td>
    </tr>
  );
  const row2 = (
    <tr key={1}>
      <th>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.annual-interest-rate2" />
      </th>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row2-1" />
      </td>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row2-2" />
      </td>
      <td>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-row2-3" />
      </td>
    </tr>
  );
  return (
    <div id="pdp-product-details-page-legal-modal">
      <h2>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.title" />
      </h2>
      <p>
        <FormattedMessage
          id="pages.pdp.legal-disclaimer.modal.p1"
          values={{
            boldP1: (
              <strong>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.boldP1" />
              </strong>
            ),
          }}
        />
      </p>
      <p>
        {annualFee ? (
          <FormattedMessage
            id="pages.pdp.legal-disclaimer.modal.p2-annual-fee"
            values={{
              boldP2: (
                <strong>
                  <FormattedMessage id="pages.pdp.legal-disclaimer.modal.boldP2" />
                </strong>
              ),
              annualFee,
            }}
          />
        ) : (
          <FormattedMessage
            id="pages.pdp.legal-disclaimer.modal.p2"
            values={{
              boldP2: (
                <strong>
                  <FormattedMessage id="pages.pdp.legal-disclaimer.modal.boldP2" />
                </strong>
              ),
            }}
          />
        )}
      </p>
      <p>
        <FormattedMessage
          id="pages.pdp.legal-disclaimer.modal.p3"
          values={{
            boldP3: (
              <strong>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.boldP3" />
              </strong>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="pages.pdp.legal-disclaimer.modal.p4"
          values={{
            boldP4: (
              <strong>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.boldP4" />
              </strong>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.p5" />
      </p>
      <p>
        <FormattedMessage id="pages.pdp.legal-disclaimer.modal.p6" />
      </p>
      <Table
        tableHeader={
          <>
            <tr>
              <th rowSpan="2">
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.label1" />
              </th>
              <th colSpan="3">
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.label2" />
              </th>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-subhead1" />
              </th>
              <th>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-subhead2" />
              </th>
              <th>
                <FormattedMessage id="pages.pdp.legal-disclaimer.modal.table.average-daily-balance-subhead3" />
              </th>
            </tr>
          </>
        }
        tableBody={[row1, row2]}
        tableStyle="unstriped"
      />
    </div>
  );
};

SRLegalModalContent.propTypes = {
  annualFee: PropTypes.string,
};

export default SRLegalModalContent;
