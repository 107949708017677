import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import PdpReviewCardList from '@cof/graffiti-alley-spray-cans/molecules/PdpReviewCardList';
import './pdp-review.scss';

const reviews = [
  {
    stars: 5,
    text: 'pages.pdp.reviews.review1.text',
    author: 'pages.pdp.reviews.review1.author',
  },
  {
    stars: 5,
    text: 'pages.pdp.reviews.review2.text',
    author: 'pages.pdp.reviews.review2.author',
  },
  {
    stars: 5,
    text: 'pages.pdp.reviews.review3.text',
    author: 'pages.pdp.reviews.review3.author',
  },
];

/*
  example of how to use pdp review contents, meant to be deleted after actual pages is created
 */

const PdpReview = ({ intl }) => {
  const formattedReviews = reviews.map((review) => ({
    ...review,
    text: intl.formatMessage({ id: review.text }),
    author: intl.formatMessage({ id: review.author }),
  }));

  return (
    <div className="pdp-review">
      <h2>
        <FormattedMessage id="pages.pdp.reviews.title" />
      </h2>
      <PdpReviewCardList reviews={formattedReviews} />
    </div>
  );
};

PdpReview.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export { PdpReview as PdpReviewRaw };

const PdpReviewHoc = injectIntl(PdpReview);
PdpReviewHoc.displayName = 'PdpReview';
export default PdpReviewHoc;
