import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardTile from '@cof/graffiti-alley-spray-cans/atoms/CardTile';
import pdpPromoteCardDesktop from '../../assets/images/pdp-promotion-card-desktop.png';
import pdpPromoteCardTablet from '../../assets/images/pdp-promotion-card-tab.png';
import pdpPromoteCardMobile from '../../assets/images/pdp-promotion-card-mob.png';
import './CardTiles.scss';

const CardTiles = () => {
  return (
    <div className="card-tiles-container">
      <CardTile containerClass="card-tiles-first-child">
        <h3>
          <FormattedMessage id="pages.pdp.content.top-features.p1" />
        </h3>
        <img src={pdpPromoteCardDesktop} alt="" className="promotion-card-desktop" />
        <img src={pdpPromoteCardTablet} alt="" className="promotion-card-tablet" />
        <img src={pdpPromoteCardMobile} alt="" className="promotion-card-mobile" />
      </CardTile>
      <div className="card-tiles-left-container">
        <CardTile containerClass="card-tiles-second-child">
          <h3>
            <FormattedMessage id="pages.pdp.content.top-features.p2" />
          </h3>
        </CardTile>
        <CardTile containerClass="card-tiles-third-child">
          <h3>
            <FormattedMessage
              id="pages.pdp.content.top-features.p3"
              values={{
                sup4: <sup>4</sup>,
              }}
            />
          </h3>
        </CardTile>
      </div>
    </div>
  );
};

export default CardTiles;
