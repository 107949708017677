import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withPrefix } from 'gatsby';
import { default as PdpLegalDisclaimerRaw } from '@cof/graffiti-alley-spray-cans/molecules/PdpLegalDisclaimer';
import PdpLegalContent from '../../ModalContent/SRLegalModalContent';

const returnLinks = (intl) => {
  const links = [
    {
      text: 'pages.pdp.legal-disclaimer.documents.document1.text',
      url: withPrefix(intl.formatMessage({ id: 'pages.pdp.legal-disclaimer.documents.document1.url' })),
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document2.text',
      url: withPrefix(intl.formatMessage({ id: 'pages.pdp.legal-disclaimer.documents.document2.url' })),
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document3.text',
      url: withPrefix(intl.formatMessage({ id: 'pages.pdp.legal-disclaimer.documents.document3.url' })),
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document4.text',
      url: withPrefix(intl.formatMessage({ id: 'pages.pdp.legal-disclaimer.documents.document4.url' })),
    },
  ];
  return links;
};

const enElements = (
  <>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note1" values={{ sup1: <sup>1</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2" values={{ sup2: <sup>2</sup> }} />
      <ol type="a" style={{ marginBottom: '0' }}>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-1" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-2" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-3" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-4" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-5" />
        </li>
      </ol>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-6" />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note3" values={{ sup3: <sup>3</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note4" values={{ sup4: <sup>4</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note5" values={{ sup5: <sup>5</sup> }} />
    </div>
    <br />
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note6" />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note7" />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note8" />
    </div>
  </>
);

const frElements = (
  <>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note1" values={{ sup1: <sup>1</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2" values={{ sup2: <sup>2</sup> }} />
      <ol type="a" style={{ marginBottom: '0' }}>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-1" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-2" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-3" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-4" />
        </li>
        <li>
          <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-5" />
        </li>
      </ol>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note2-6" values={{ md: <sup>MD</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note3" values={{ sup3: <sup>3</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note4" values={{ sup4: <sup>4</sup> }} />
    </div>
    <br />
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note6" />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note7" />
    </div>
    <div>
      <FormattedMessage id="pages.pdp.legal-disclaimer.notes.note8" />
    </div>
  </>
);

const PdpLegalDisclaimer = injectIntl(({ intl, snippetDetails }) => {
  const annualFeeSnippet = snippetDetails.find((snippet) => snippet.name === 'annual-fee');
  const links = returnLinks(intl);
  return (
    <PdpLegalDisclaimerRaw
      legalTextId="pages.pdp.legal-disclaimer.legal"
      legalModal={() => <PdpLegalContent annualFee={annualFeeSnippet?.value} />}
      links={links}
      legalTextElements={intl.locale === 'fr' ? frElements : enElements}
    />
  );
});

export default PdpLegalDisclaimer;
